import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { toast } from 'react-toastify';
import './Modal-usuarios.css';
import { useEffect, useState } from "react";
import api from "../../api.js";
import { Checkbox, FormControlLabel } from '@mui/material';

function ModalUsuarios({ statusModal, onCloseModal, selectedId }) {

    const [nome, setNome] = useState("");
    const [email, setEmail] = useState("");
    const [senha, setSenha] = useState("");
    const [admin, setAdmin] = useState("N");
    const token = localStorage.getItem('sessionToken');
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

    useEffect(() => {
        (async () => {
            try {
                //alert('Chegou:' +selectedId);
                if (!selectedId) return;
                const { data } = await api.get("/user/" + selectedId, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (!data) return;
                if (data[0]) {
                    const obj = (data[0]);
                    const { id, nome, email, password, administrador } = obj;
                    setNome(nome);
                    setEmail(email);
                    setSenha(password);
                    setAdmin(administrador);
                }
            } catch (e) {
                toast.error("Problema na rotina: ", e);
            }
        })();
    }, [selectedId]);

    const handleClose = () => {
        setNome("");
        setEmail("");
        setSenha("");
        setAdmin("");
        onCloseModal();
    }

    const handleInserir = async () => {
        if (!nome) {
            toast.error("Nome deve ser preenchido")
            return;
        }
        if (!email) {
            toast.error("Email deve ser preenchido")
            return;
        }
        if (!senha) {
            toast.error("Senha deve ser preenchida")
            return;
        }
        try {
            const obj =
            {
                nome: nome,
                email: email,
                password: senha,
                administrador: admin
            }
            const response = await api.post("/createUser", obj, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            toast.success('Dados inseridos com sucesso:', response.data);
            handleClose();
        } catch (error) {
            toast.error('Erro ao incluir os dados:', error);
        }
    }

    const handleUpdate = async () => {
        if (!nome) {
            toast.error("Nome deve ser preenchido")
            return;
        }
        if (!email) {
            toast.error("Email deve ser preenchido")
            return;
        }
        if (!senha) {
            toast.error("Senha deve ser preenchida")
            return;
        }
        try {
            const obj =
            {
                nome: nome,
                email: email,
                password: senha,
                administrador: admin
            }
            const response = await api.put("/users/" + selectedId, obj, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            toast.success('Dados atualizados com sucesso:', response.data);
            handleClose();
        } catch (error) {
            toast.error('Erro ao atualizar os dados:', error);
        }
    };

    return (
        <div>
            <Dialog className="usuario-d-container" open={statusModal} onClose={handleClose} scroll="paper" fullWidth maxWidth="lg">
                <DialogTitle>Usuário</DialogTitle>
                <DialogContent className="usuario-dl-container" dividers>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
                        <TextField
                            id="nome"
                            label="Nome"
                            variant="standard"
                            margin="dense"
                            value={nome}
                            onChange={(e) => setNome(e.target.value)}
                        />
                        <TextField
                            id="email"
                            label="Email/Login"
                            variant="standard"
                            margin="dense"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        {/* <TextField
                            id="senha"
                            label="Senha"
                            variant="standard"
                            margin="dense"
                            type="password"
                            value={senha}
                            onChange={(e) => setSenha(e.target.value)}
                        /> */}
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={admin === 'S'}
                                    onChange={(e) => setAdmin(e.target.checked ? 'S' : 'N')}
                                    name="adm"
                                    color="primary"
                                />
                            }
                            label="Administrador"
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <button className="usuario-btn-fechar" onClick={handleClose}>Fechar</button>
                    <button className="usuario-btn-incluir" onClick={selectedId ? handleUpdate : handleInserir}>Salvar</button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ModalUsuarios;