import axios from "axios";

const api = axios.create({
  baseURL: "https://apinfse.goneweb.com.br",
  // withCredentials: true
});

export const apiNFSeHomologacao = axios.create({
  baseURL: "https://homologacao.focusnfe.com.br/v2",
});

export const apiNFSeProducao = axios.create({
  baseURL: "https://api.focusnfe.com.br",
});

export default api;
