import "./Compromissos.css";
import { useEffect, useState } from "react";
import ModalCompromissos from '../../components/Modal/Modal-compromissos.jsx';
import { DataGrid } from "@mui/x-data-grid";
import { TextField } from '@mui/material';

import api from "../../api.js";

const Compromissos = () => {

    const [openModal, setOpenModal] = useState(false);
    const [compromisso, setCompromisso] = useState([]);

    const dataAtual = new Date();
    // const dataFormatada = dataAtual.toLocaleDateString('pt-BR');

    const date = new Date();
    const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const [selectedId, setSelectedId] = useState(null);
    const [primeiroDiaString, setPrimeiroDiaString] = useState(`${primeiroDia.getFullYear()}-${(primeiroDia.getMonth() + 1).toString().length < 2 ? `0${primeiroDia.getMonth() + 1}` : primeiroDia.getMonth() + 1}-${primeiroDia.getDate().toString().length < 2 ? `0${primeiroDia.getDate()}` : primeiroDia.getDate()}T${primeiroDia.getHours().toString().length < 2 ? `0${primeiroDia.getHours()}` : primeiroDia.getHours()}:${primeiroDia.getMinutes().toString().length < 2 ? `0${primeiroDia.getMinutes()}` : primeiroDia.getMinutes()}`);
    const [lastDayDate, setLastDayDate] = useState(`${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().length < 2 ? `0${lastDay.getMonth() + 1}` : lastDay.getMonth() + 1}-${lastDay.getDate().toString().length < 2 ? `0${lastDay.getDate()}` : lastDay.getDate()}T${lastDay.getHours().toString().length < 2 ? `0${lastDay.getHours()}` : lastDay.getHours()}:${lastDay.getMinutes().toString().length < 2 ? `0${lastDay.getMinutes()}` : lastDay.getMinutes()}`);
    const [total, setTotal] = useState(0);
    const [filtro, setFiltro] = useState("0");

    const filtroStatus = ['Todos', 'Abertos', 'Concluidos'];

    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/compromissos");
                if (data)
                    setCompromisso(data);
            } catch (e) {
                alert("Problema na rotina: " + e);
            }
        })();
    }, [setCompromisso]);

    const columns = [
        //  { field: 'id', headerName: 'id', width: 70 },
        { field: 'descricao', headerName: 'Descrição', width: 300, maxWidth: 300, flex: 1 },
        {
            field: 'vencimento',
            headerName: 'Vencimento',
            align: 'center',
            headerAlign: 'center',
            flex: 0.5,
            type: 'date',
            valueGetter: (value) => value && new Date(value)
        },
        {
            field: 'status',
            headerName: 'Status',
            // headerAlign: 'center',
            width: 100,
            // renderCell: (params) => (
            //     <strong style={{ backgroundColor: params.row.status === 'pendente' ? 'red' : 'green' }}>
            //         {params.value}
            //     </strong>
            // )
        },
        {
            field: 'actions',
            headerName: '*',
            width: 100,
            renderCell: (params) => (
                <button className='btn-concluir' onClick={() => handleButtonConcluir(params.id)}>
                    Concluir
                </button>
            )
        },
        {
            field: "exclude",
            headerName: '*',
            width: 100,
            renderCell: (params) => (
                <button className='btn-excluir' onClick={() => handleButtonExcluir(params.id)}>
                    Excluir
                </button>
            )
        }

    ];

    const [rows, setRows] = useState([
        { id: 1, data: '01/01/2010', desc: 'Lavar o carro', status: 'pendente' },
        { id: 2, data: '01/01/2020', desc: 'Fazer compras no supermercado', status: 'pendente' },
    ]);

    function handleCloseModal() {
        setOpenModal(false);
    }
    function handleOpenModal() {
        setOpenModal(true);
    }

    function handleButtonConcluir(event) {
        alert('Concluir  ' + event)
    }

    function handleButtonExcluir(event) {
        alert('Excluir ' + event)
    }

    const handleFiltro = async (e) => {
        let status = "Todas";
        if (filtro === "1") {
            status = "Aberto"
        }
        if (filtro === "2") {
            status = "Concluido"
        }

        // try {
        //     setFaturamentos([]);
        //     const { data: faturamentos } = await api.get("/faturamento/" + primeiroDiaString + "/" + lastDayDate + "/" + status);
        //     if (faturamentos) {
        //         setFaturamentos(faturamentos);
        //         let iptot = 0;
        //         faturamentos.map((fat) => {
        //             iptot = iptot + parseFloat(fat.valor);
        //             return iptot;
        //         });
        //         let numeroComDecimais = iptot.toFixed(2);
        //         let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
        //         setTotal(numeroFormatadoComDecimais);
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
    }    


    return (
        <div className='container-compromissos'>
            <ModalCompromissos statusModel={openModal} onCloseModal={handleCloseModal} rows={rows} setRows={setRows} ></ModalCompromissos>
            
            <div className="container-top-compromisso">
                <div className="column-top-compromisso">
                    <h2 style={{ color: '#fff' }} >Relação de compromissos</h2>
                </div>
                <div className="column-top-btn-fat">
                    <button onClick={handleOpenModal}>Novo</button>
                </div>
            </div>

            <div className="nf-filtro-comp">
                <h2 style={{padding:10}}>Filtros: </h2>
                <TextField
                    id="datetime-inicio"
                    label="Inicial"
                    type="datetime-local"
                    defaultValue={primeiroDiaString}
                    variant="filled"
                    className="custom-textfield custom-textfield-height"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <span><h3 style={{padding:10}}>até</h3> </span>
                <TextField
                    id="datetime-final"
                    label="Final"
                    variant="filled"
                    type="datetime-local"
                    defaultValue={lastDayDate}
                    className="custom-textfield custom-textfield-height"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <select className='btn-filtrar-comp'
                    required
                >
                    {
                        filtroStatus.map((number, index) => (
                            <option key={index} value={index}>{number}</option>
                        ))
                    }
                </select>
                <button className='btn-filtrar' onClick={handleFiltro} >Filtrar</button>

                <div className='div-total-comp'>
                    <h2 style={{padding:10}}>Total do filtro: R$ {total}</h2>
                </div>
            </div>
           
            <div className="nf-table-comp">
                <DataGrid
                    rows={compromisso}
                    columns={columns}
                    disableColumnResize={true}
                    sx={{ fontSize: 14, backgroundColor: '#fff', borderRadius: 5 }}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 5 },
                        },
                    }}
                    pageSizeOptions={[5, 10]}
                />
            </div>
        </div>
    )
}

export default Compromissos;