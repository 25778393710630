import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, MenuItem, Checkbox, Select, FormControlLabel } from "@mui/material";
import { toast } from 'react-toastify';
import './Modal-emitente.css';
import { useEffect, useState } from "react";
import api from "../../api";
// import InputLabel from '@mui/material/InputLabel';
// import Select from '@mui/material/Select';

function ModalEmitente({ statusModal, onCloseModal, selectedId }) {

    const [cnpj, setCnpj] = useState("");
    const [ie, setIe] = useState("");
    const [icm, setIcm] = useState("");
    const [razao, setRazao] = useState("");
    const [nome, setNome] = useState("");
    const [endereco, setEndereco] = useState("");
    const [bairro, setBairro] = useState("");
    const [numero, setNumero] = useState("");
    const [selectedUf, setSelectedUf] = useState("");
    const [selectedCidade, setSelectedCidade] = useState("");
    const [cep, setCep] = useState("");
    const [ibge, setIbge] = useState("");
    const [ufs, setUfs] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [codtrib, setCodTrib] = useState("");
    const [tokenHomologacao, setTokenHomologacao] = useState("");
    const [tokenProducao, setTokenProducao] = useState("");
    const [loginPrefeitura, setLoginPrefeitura] = useState("");
    const [senhaPrefeitura, setSenhaPrefeitura] = useState("");
    const [rps, setRps] = useState(0);
    const [checkBoxSimples, setCheckBoxSimples] = useState(false);
    const [chave, setChave] = useState("");
    const [chaveAcesso, setChaveAcesso] = useState("");

    useEffect(() => {
        (async () => {
            try {
                const { data: estados } = await api.get("/estados");
                if (estados) {
                    setUfs(estados);
                }
            } catch (error) {
                toast.error(error);
            }
        })();
    }, [setUfs]);

    useEffect(() => {
        (async () => {
            try {
                if (!selectedId) return;

                const token = localStorage.getItem('sessionToken');
                const { data } = await api.get("/emitente/" + selectedId, {
                    headers: {
                        Authorization: `Bearer ${token}` 
                    }
                });

                //const { data } = await api.get("/emitente/" + selectedId);
                if (!data) return;
                if (data[0]) {
                    const obj = (data[0]);
                    const { razao, cnpj, cep, ibge, insc_municipal, ie, nome, endereco, bairro, id_cidade, uf, numero, cod_tributario_municipio, token_homologacao, token_producao, login_prefeitura, senha_prefeitura, ultimo_rps, simples_nacional, chave_acesso } = obj;
                    setRazao(razao);
                    setCnpj(cnpj);
                    setCep(cep);
                    setIbge(ibge);
                    setIe(ie);
                    setEndereco(endereco);
                    setNome(nome);
                    setBairro(bairro);
                    handleEstado(uf);
                    setSelectedCidade(id_cidade === 0 ? "" : id_cidade);
                    setNumero(numero);
                    setIcm(insc_municipal);
                    setCodTrib(cod_tributario_municipio);
                    setTokenHomologacao(token_homologacao);
                    setTokenProducao(token_producao);
                    setLoginPrefeitura(login_prefeitura);
                    setSenhaPrefeitura(senha_prefeitura);
                    setRps(ultimo_rps);
                    setCheckBoxSimples(simples_nacional === "S");
                    setChaveAcesso(chave_acesso);
                }
            } catch (e) {
                toast.error("Problema na rotina: ", e);
            }
        })();
    }, [selectedId]);


    const handleClose = () => {
        setRazao("");
        setCnpj("");
        setCep("");
        setIbge("");
        setIe("");
        setEndereco("");
        setNome("");
        setBairro("");
        setSelectedUf("");
        setSelectedCidade("");
        setLoginPrefeitura("");
        setSenhaPrefeitura("");
        setNumero("");
        setIcm("");
        setCodTrib("");
        setTokenHomologacao("");
        setTokenProducao("");
        onCloseModal();
        setRps(0);
        setCheckBoxSimples(false);
        setChave("");
        setChaveAcesso("");
    }

    const handleInserir = async () => {
        if (!nome) {
            toast.error("Nome deve ser preenchido")
            return;
        }
        if (!razao) {
            toast.error("Razão Social deve ser preenchida")
            return;
        }
        if (!cnpj) {
            toast.error("CNPJ deve ser preenchido")
            return;
        }
        if (!icm) {
            toast.error("Incr. Municipal deve ser preenchida")
            return;
        }
        if (!selectedUf) {
            toast.error("Selecione um estado válido")
            return;
        }
        if (!selectedCidade) {
            toast.error("Selecione uma cidade válida")
            return;
        }
        if (!cep) {
            toast.error("Selecione um CEP válido")
            return;
        }
        if (!codtrib) {
            toast.error("Selecione um codigo tributário")
            return;
        }
        if (!ibge) {
            toast.error("IBGE deve ser preenchido")
            return;
        }
        try {
            const obj =
            {
                nome: nome,
                razao: razao,
                cnpj: cnpj,
                cep: cep,
                ibge: ibge,
                insc_municipal: icm,
                ie: ie,
                endereco: endereco,
                bairro: bairro,
                numero: numero,
                login_prefeitura: loginPrefeitura,
                senha_prefeitura: senhaPrefeitura,
                uf: selectedUf,
                id_cidade: selectedCidade,
                cod_tributario_municipio: codtrib,
                ultimo_rps: rps,
                simples_nacional: checkBoxSimples ? "S" : "N"
            }
            const token = localStorage.getItem('sessionToken');
            const { response } = await api.post("/createEmitente", obj, {
                headers: {
                    Authorization: `Bearer ${token}` 
                }
            });          
            handleClose();
        } catch (error) {
            console.error('Erro ao incluir os dados:', error);
        }
    }

    const handleUpdate = async () => {
        if (!selectedUf) {
            toast.warn("Selecione um estado válido");
            return;
        }
        try {
            const obj =
            {
                nome: nome,
                razao: razao,
                cnpj: cnpj,
                cep: cep,
                ibge: ibge,
                insc_municipal: icm,
                ie: ie,
                login_prefeitura: loginPrefeitura,
                senha_prefeitura: senhaPrefeitura,
                endereco: endereco,
                bairro: bairro,
                numero: numero,
                uf: selectedUf,
                id_cidade: selectedCidade,
                cod_tributario_municipio: codtrib,
                ultimo_rps: rps,
                simples_nacional: checkBoxSimples ? "S" : "N",
                chave_acesso: chaveAcesso
            }
            const token = localStorage.getItem('sessionToken');
            const { response } = await api.put("/emitente/" + selectedId, obj, {
                headers: {
                    Authorization: `Bearer ${token}` 
                }
            });
            
//            const response = await api.put("/emitente/" + selectedId, obj);
            handleClose();
        } catch (error) {
            console.error('Erro ao atualizar os dados:', error);
        }
    };

    const handleEstado = async (e) => {
        setSelectedUf(e);
        setSelectedCidade("");
        const { data: cidades } = await api.get("/cidades/" + e);
        if (cidades) {
            setCidades(cidades);
        }
    }

    const handleCidade = (idCid) => {
        setSelectedCidade(idCid);
        const dCid = cidades.filter((cid) => cid.id === idCid);
        setIbge(dCid[0]?.codigo_ibge);
    }

    return (
        <div>
            <Dialog className="d-container-emitente" open={statusModal} onClose={handleClose} scroll="paper" fullWidth maxWidth="lg">
                <DialogTitle>Editar Emitente</DialogTitle>
                <DialogContent className="dl-container" dividers>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
                        <TextField
                            required
                            id="cnpj"
                            label="CNPJ"
                            variant="standard"
                            margin="dense"
                            value={cnpj}
                            onChange={(e) => setCnpj(e.target.value)}
                        />
                        <TextField
                            required
                            id="ie"
                            label="Incr Estadual"
                            variant="standard"
                            margin="dense"
                            value={ie}
                            onChange={(e) => setIe(e.target.value)}
                        />
                        <TextField
                            required
                            id="icm"
                            label="Incr. Municipal"
                            variant="standard"
                            margin="dense"
                            value={icm}
                            onChange={(e) => setIcm(e.target.value)}
                        />
                        <TextField
                            required
                            id="codtrib"
                            label="Cod.Tributário"
                            variant="standard"
                            margin="dense"
                            value={codtrib}
                            onChange={(e) => setCodTrib(e.target.value)}
                        />
                        <FormControlLabel
                            control={<Checkbox checked={checkBoxSimples} onChange={(e) => setCheckBoxSimples(e.target.checked)} />}
                            label="Simples Nacional"
                        />
                    </div>
                    <TextField
                        required
                        id="razao"
                        label="Razão"
                        margin="dense"
                        variant="standard"
                        fullWidth
                        value={razao}
                        onChange={(e) => setRazao(e.target.value)}
                    />
                    <TextField
                        required
                        id="fantasia"
                        label="Nome Fantasia"
                        margin="dense"
                        variant="standard"
                        fullWidth
                        value={nome}
                        onChange={(e) => setNome(e.target.value)}
                    />
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
                        <TextField
                            required
                            id="cep"
                            label="CEP"
                            margin="dense"
                            variant="standard"
                            value={cep}
                            onChange={(e) => setCep(e.target.value)}
                        />
                        <TextField
                            required
                            id="endereco"
                            label="Endereço"
                            margin="dense"
                            variant="standard"
                            fullWidth
                            value={endereco}
                            onChange={(e) => setEndereco(e.target.value)}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
                        <TextField
                            required
                            id="bairro"
                            label="Bairro"
                            variant="standard"
                            margin="dense"
                            value={bairro}
                            onChange={(e) => setBairro(e.target.value)}
                        />
                        <TextField
                            required
                            id="numero"
                            label="Numero"
                            variant="standard"
                            margin="dense"
                            value={numero}
                            onChange={(e) => setNumero(e.target.value)}
                        />
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedUf}
                            label="Estado"
                            onChange={(e) => handleEstado(e.target.value)}
                        >
                            <MenuItem key={"nostate"} value="">Selecione um estado</MenuItem>
                            {ufs && ufs.length > 0 && ufs.map((estado) =>
                                <MenuItem key={estado.codigo_uf} value={estado.codigo_uf}>{estado.nome}</MenuItem>
                            )}
                        </Select>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedCidade}
                            label="Estado"
                            onChange={(e) => handleCidade(e.target.value)}
                        >
                            <MenuItem key={"nostate"} value="">Selecione uma cidade</MenuItem>
                            {cidades && cidades.length > 0 && cidades.map((cid) =>
                                <MenuItem key={cid.codigo_ibge} value={cid.id}>{cid.nome}</MenuItem>
                            )}
                        </Select>
                    </div>
                    <br></br>
                    <br></br>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
                        <TextField
                            id="login_prefeitura"
                            label="Login da Prefeitura"
                            margin="dense"
                            variant="standard"
                            onChange={(e) => setLoginPrefeitura(e.target.value)}
                            value={loginPrefeitura}
                            fullWidth
                        />
                        <TextField
                            id="senha_prefeitura"
                            label="Senha da Prefeitura"
                            margin="dense"
                            variant="standard"
                            type="password"
                            onChange={(e) => setSenhaPrefeitura(e.target.value)}
                            fullWidth
                            value={senhaPrefeitura}
                        />
                        <TextField
                            id="ultimo_rps"
                            label="Próxima NFSe"
                            margin="dense"
                            variant="standard"
                            type="number"
                            onChange={(e) => setRps(e.target.value)}
                            fullWidth
                            value={rps}
                        />
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
                        <TextField
                            id="token_homologacao"
                            label="Token NFSe Homologação"
                            margin="dense"
                            variant="standard"
                            value={tokenHomologacao}
                            fullWidth
                        />
                        <TextField
                            id="token_producao"
                            label="Token NFSe Produção"
                            margin="dense"
                            variant="standard"
                            fullWidth
                            value={tokenProducao}
                        />
                        <TextField
                            id="chave_acesso"
                            label="Chave Acesso"
                            margin="dense"
                            variant="standard"
                            fullWidth
                            value={chaveAcesso}
                            onChange={(e) => setChaveAcesso(e.target.value)}
                        />
                    </div>
                    <br></br>
                </DialogContent>
                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Fechar</button>
                    <button className="btn-incluir" onClick={selectedId ? handleUpdate : handleInserir}>Salvar</button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ModalEmitente;