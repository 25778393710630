import './Despesa.css';
import { TextField } from '@mui/material';
import { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';

const Despesas = () => {

    const [openModalNova, setOpenModalNova] = useState(false);

    function handleCloseModalNova() {
        setOpenModalNova(false);
    }
    
    function handleOpenModalNova() {
        setOpenModalNova(true);
    }

    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'vencto', headerName: 'Lançamento', width: 120 },
        { field: 'descricao', headerName: 'Descricao', width: 300 },
        { field: 'categoria', headerName: 'Categoria', width: 200 },
        { field: 'valor', headerName: 'Valor', width: 120 },
        { field: 'status', headerName: 'Status', width: 100 },
        {
            field: 'baixar',
            headerName: 'P',

            width: 100,
            renderCell: (params) => (
                <button className='btn-enviar' onClick={() => handleButtonConcluir(params.id)}>
                    Baixar
                </button>
            )
        },
        {
            field: "excluir",
            headerName: 'E',
            width: 100,
            renderCell: (params) => (
                <button className='btn-cancelar' onClick={() => handleButtonExcluir(params.id)}>
                    Excluir
                </button>
            )
        }

    ];

    const [rows, setRows] = useState([
        { id: 1, vencto: '01/01/2010', descricao: 'Compra no supermercado', valor: 'R$ 1500,00', status: 'Pendente', categoria: 'Pessoais' },
        { id: 2, vencto: '01/01/2010', descricao: 'Combustivel', valor: 'R$ 1500,00', status: 'Pendente', categoria: 'Administrativa' },
        { id: 3, vencto: '01/01/2010', descricao: 'Aluguel', valor: 'R$ 1500,00', status: 'Pendente', categoria: 'Administrativa' },
    ]);

    function handleButtonConcluir(event) {
        alert('Concluir  ' + event)
    }

    function handleButtonExcluir(event) {
        alert('Excluir ' + event)
    }

    const date = new Date();
    const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
    let primeiroDiaString = `${primeiroDia.getFullYear()}-${(primeiroDia.getMonth()+1).toString().length < 2 ? `0${primeiroDia.getMonth()+1}` : primeiroDia.getMonth()+1}-${primeiroDia.getDate().toString().length < 2 ? `0${primeiroDia.getDate()}` : primeiroDia.getDate()}T${primeiroDia.getHours().toString().length < 2 ? `0${primeiroDia.getHours()}` : primeiroDia.getHours()}:${primeiroDia.getMinutes().toString().length < 2 ? `0${primeiroDia.getMinutes()}` : primeiroDia.getMinutes()}`;
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    let lastDayDate = `${lastDay.getFullYear()}-${(lastDay.getMonth()+1).toString().length < 2 ? `0${lastDay.getMonth()+1}` : lastDay.getMonth()+1}-${lastDay.getDate().toString().length < 2 ? `0${lastDay.getDate()}` : lastDay.getDate()}T${lastDay.getHours().toString().length < 2 ? `0${lastDay.getHours()}` : lastDay.getHours()}:${lastDay.getMinutes().toString().length < 2 ? `0${lastDay.getMinutes()}` : lastDay.getMinutes()}`;

    return (
        <div className='container-despesas'>
            {/* <ModalNfse statusModal={openModalNova} onCloseModalNova={handleCloseModalNova} rows={rows} setRows={setRows} ></ModalNfse> */}
            <div className="despesa-top">
                <p className='div-p-top'>Relação de Despesas</p>
                <button onClick={handleOpenModalNova}>Nova despesa</button>
            </div>
            <div className="despesa-filtro">
                <h3>Filtros: </h3>
                <TextField
                    id="datetime-inicio"
                    label="Inicial"
                    type="datetime-local"
                    defaultValue={primeiroDiaString}
                    variant="filled"
                    className="custom-textfield custom-textfield-height"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <span><h3>até</h3> </span>
                <TextField
                    id="datetime-final"
                    label="Final"
                    variant="filled"
                    type="datetime-local"
                    defaultValue={lastDayDate}
                    className="custom-textfield custom-textfield-height"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                <button className='btn-filtrar'>Filtrar</button>
                <div className='div-total'>
                    <h2>Total do filtro: R$ 15700,00</h2>
                </div>
            </div>
            <div className="despesa-table">
                <DataGrid
                    rows={rows}
                    columns={columns}
                    disableColumnResize={true}
                    initialState={{
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20, 50]}
                />
            </div>
        </div>
    );
}

export default Despesas;