import React, { useState } from 'react';
import api from '../../api.js';
import axios from 'axios';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem } from "@mui/material";

function UploadArquivo({ statusModal, onCloseModal, selectedId }) {

  const [file, setFile] = useState(null);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Por favor, selecione um arquivo.');
      return;
    }

    const formData = new FormData();
    formData.append('arquivo', file); // Nome do campo deve coincidir com o esperado no backend
    formData.append('id', selectedId); // ID do registro a ser atualizado

    // primeiro fazer upload do arquivo
    try {
      const response = await api.post('/imposto/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      // console.log(response.data);
      // console.log(response.data.filePath);
      // Tratar resposta de sucesso
      handleClose();
    } catch (error) {
      console.error('Erro no upload:', error);
      // Tratar erro de upload
    }
  };

  const handleClose = () => {
    setFile(null);
    onCloseModal();
  }

  return (
    <div>
      <Dialog className="d-container" open={statusModal} onClose={handleClose} scroll="paper" fullWidth maxWidth="lg">
        <DialogTitle>Envio de arquivo</DialogTitle>
        <DialogContent className="dl-container" dividers>
          <div style={{ display: "flex", justifyContent: "space-between", gap: "15px" }}>
            <TextField
              id="arquivo"
              label="Arquivo"
              variant="standard"
              margin="dense"
              type='file'
              // value={aliquota}
              onChange={handleFileChange}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <button className="btn-fechar" onClick={handleClose}>Fechar</button>
          <button className="btn-incluir" onClick={handleSubmit}>Enviar</button>
        </DialogActions>
      </Dialog>
    </div>
  );
}


export default UploadArquivo;