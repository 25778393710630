import './Credores.css';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
// import { DataGridPremium } from '@mui/x-data-grid-premium';
import { useEffect, useState } from 'react';
import ModalCredor from '../Modal/Modal-Credor.jsx';
import api from '../../api.js';
import { Box } from '@mui/material';
import ModalConfirmacao from '../Modal/Modal-Confirmacao.jsx';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';

function Credores() {
    const navigate = useNavigate();
    const [openModalNova, setOpenModalNova] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [credor, setCredor] = useState([]);
    const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);
    const [status, setStatus] = useState('Ativo');
    const apiRef = useGridApiRef();
    const [selectedEmitente, setSelectedEmitente] = useState(0);
    const [emitente, setEmitente] = useState([]);

    const acesso = localStorage.getItem('sessionChaveAcesso');
    const acessoContador = localStorage.getItem("sessionContador");
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

    const [totalValorFormatado, setTotalValorFormatado] = useState(0);

    const columns = [
        {
            field: 'editar',
            headerName: '',
            width: 60,
            renderCell: (params) => (
                <Avatar src={'https://img.icons8.com/fluency/48/edit-text-file.png'} alt=''
                    className='btn-ativa-pagar'
                    onClick={() => handleButtonEditar(params.id)}
                    title='Editar'
                />
            )
        },
        { field: 'cnpj', headerName: 'Cnpj/Cpf', width: 140 },
        { field: 'razao', headerName: 'Razao', width: 300, flex: 1, },
        { field: 'nome', headerName: 'Nome', width: 250, flex: 0.8, },
        { field: 'nome_cidade', headerName: 'Cidade', width: 100, flex: 0.6 },
        { field: 'nome_estado', headerName: 'UF', width: 80, flex: 0.5, align: 'center', headerAlign: 'center' },
        { field: 'dia_vencimento', headerName: 'Vence', width: 30, flex: 0.2, align: 'center', headerAlign: 'center' },
        {
            field: 'valor', headerName: 'Valor', width: 50, flex: 0.3, align: 'center', headerAlign: 'center'
        },
        {
            field: 'deletar',
            headerName: '',
            align: 'center',
            width: 80,
            renderCell: (params) => (
                <Avatar src={'https://img.icons8.com/plasticine/100/filled-trash.png'} alt=''
                    className='btn-ativa-pagar'
                    onClick={() => handleButtonExcluir(params.id)}
                    title='Excluir'
                />
            )
        }
    ];

    const fetchEmitentes = async () => {
        try {
            const response = await api.get(`/emitentesAll/${acessoContador}/${acesso}`, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            const newToken = response.headers['newtoken'];
            if (newToken) {
                localStorage.setItem('sessionToken', newToken);
                setTokenSession(newToken);
            }
            // Trabalha com os dados da resposta
            if (response.data) {
                setEmitente(response.data);
                if (response.data.length > 0) {
                    setSelectedEmitente(response.data[0].id);
                }
            } else { setSelectedEmitente(0); }
        } catch (error) {
            toast.error('Erro ao buscar token, faça login novamente :', error);
            navigate('/login');
        }
    };

    // Select emitentes
    useEffect(() => {
        fetchEmitentes();
    }, []);

    const onExcluir = (async (id) => {
        try {
            // ver se tem contas lancadas
            const temConta = await api.get("/credorConta/" + id);
            if (temConta.data.message !== 'Nenhuma credor encontrado.') {
                toast.warning('Credor possui conta lançada, não pode ser excluido.');
            } else {
                const exclui = await api.delete("/credor/" + id);
                toast.success(exclui.data);
                atualizaTela();
            }
        } catch (error) {
            toast.error('Erro:' + error);
        }
    })

    const handleButtonExcluir = (async (id) => {
        setSelectedId(id);
        setOpenModalConfirmacao(true);
    })

    function handleButtonEditar(id) {
        setSelectedId(id);
        setOpenModalNova(true);
    }

    function handleCloseModalNova() {
        setSelectedId(null);
        setOpenModalNova(false);
        atualizaTela();
    }

    function handleOpenModalNova() {
        setOpenModalNova(true);
    }

    let totalValor = 0;

    const atualizaTela = () => {
        (async () => {
            try {
                const { data } = await api.get("/credores/" + selectedEmitente);
                if (data.data.data) {
                    setCredor(data.data.data);
                }
            } catch (e) {
                toast.error("Problema na rotina: " + e);
            }
            totalizaFiltro();
        })();
    }

    useEffect(() => {
        if (!selectedEmitente) return;
        (async () => {
            try {
                const data = await api.get("/credores/" + selectedEmitente, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                const newToken = data.headers['newtoken'];
                if (newToken) {
                    localStorage.setItem('sessionToken', newToken);
                    setTokenSession(newToken);
                }
                if (data.data.data) {
                    setCredor(data.data.data);
                }
            } catch (e) {
                toast.error("Problema na rotina: " + e);
            }
        })();
    }, [status, selectedEmitente]);

    useEffect(() => {
        totalizaFiltro();
    }, [credor]);

    const totalizaFiltro = async () => {
        if (Array.isArray(credor) && credor.length > 0) {
            totalValor = credor.reduce((acc, row) => acc + Number(row.valor || 0), 0);
        
            // Formata o total no padrão brasileiro
            setTotalValorFormatado(
                new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2, // Garante 2 casas decimais
                    maximumFractionDigits: 2, // Garante 2 casas decimais
                    style: 'currency',        // Estilo monetário
                    currency: 'BRL',          // Moeda brasileira
                }).format(totalValor)
            );
        } else {
            // Caso credor não seja um array válido, define como 0
            setTotalValorFormatado(
                new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                    style: 'currency',
                    currency: 'BRL',
                }).format(0)
            );
        }        
    }

    function handleCloseModalConfirmacao() {
        setOpenModalConfirmacao(false);
    }

    const filtroStatus = ['Ativos', 'Inativos', 'Todos'];

    const handleSetStatus = (st) => {
        setStatus(st);
        if (st === "Ativos") { setStatus('Ativo'); }
        if (st === "Inativos") { setStatus('Inativo'); }
    }

    function getRowClassName(params) {
        const status = params.row.ativo; // Suponha que a coluna "status" contenha o status da linha
        if (status === 'S') {
            return 'linha-ativo'; // Classe CSS para linhas aprovadas
        } else if (status === 'N') {
            return 'linha-inativo'; // Classe CSS para linhas pendentes
        } else {
            return ''; // Classe padrão para outras linhas
        }
    }

    return (
        <div className="container-credores">
            <ModalCredor statusModal={openModalNova} onCloseModal={handleCloseModalNova} selectedId={selectedId} ></ModalCredor>
            <ModalConfirmacao statusModal={openModalConfirmacao} onCloseModalConfirmacao={handleCloseModalConfirmacao} onExcluir={onExcluir} selectedId={selectedId} titulo='Confirma a exclusão' origem='Credores'></ModalConfirmacao>
            <div className="credor-container-top">
                <div className="credor-column-top">
                    <h2 style={{ color: '#fff' }} >Cadastro de Credores</h2>
                </div>
                <div className="btn-novo-credor">
                    <button onClick={handleOpenModalNova}>Novo</button>
                </div>
            </div>
            <div className='div-filtro-credores'>
                <label>Filtrar por :</label>
                <select className='filtrar-credor'
                    onChange={(e) => handleSetStatus(e.target.value)}
                    required
                >
                    {
                        filtroStatus.map((number, index) => (
                            <option key={index} value={index}>{number}</option>
                        ))
                    }
                </select>
                <select className='filtrar-credor' style={{ width: 200, border: 'none' }}
                    onChange={(e) => setSelectedEmitente(e.target.value)}
                    required
                    value={selectedEmitente ? selectedEmitente : ''}
                >
                    <option value="">Selecione um emitente</option>
                    {
                        Array.isArray(emitente) && emitente.length > 0 ? (
                            emitente.map((emit) => (
                                <option key={emit.id} value={emit.id}>{emit.nome}</option>
                            ))
                        ) : (
                            <option value="">Nenhum emitente disponível</option>
                        )
                    }
                </select>
                <h2 style={{ color: '#fff', margin: 'auto' }}>Total do filtro: {totalValorFormatado}</h2>
            </div>

            <div className="credor-meio">
                <Box sx={{ height: '100%', width: '100%' }}>
                    <DataGrid
                        rows={credor ?? []}
                        columns={columns}
                        getRowClassName={getRowClassName}
                        apiRef={apiRef}
                        showColumnVerticalBorder
                        showCellVerticalBorder
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                    // disableRowSelectionOnClick
                    // sx={{
                    // '& .MuiDataGrid-columnHeaders': {
                    // position: 'sticky',
                    // top: 0,
                    // zIndex: 1, // Garante que o header ficará acima do conteúdo
                    // backgroundColor: '#fff', // Cor de fundo para evitar sobreposição
                    // },
                    // }}
                    />
                </Box>
            </div>
        </div>
    );
}

export default Credores;