import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { toast } from 'react-toastify';
import './Modal-Nfse.css';
import { useEffect, useState } from "react";
import api from "../../api";
import { useNavigate } from 'react-router-dom';


function ModalNfse({ statusModal, onCloseModalNova, selectedId, operacao }) {
    const navigate = useNavigate();
    const [emitentes, setEmitentes] = useState([]);
    const [clientes, setClientes] = useState([]);
    const [servicos, setServicos] = useState([]);
    const [cidades, setCidades] = useState([]);
    const [valor, setValor] = useState(0);
    const [descricao, setDescricao] = useState("");
    const [selectedCidade, setSelectedCidade] = useState(0);
    const [selectedEmitente, setSelectedEmitente] = useState(0);
    const [selectedCliente, setSelectedCliente] = useState(0);
    const [selectedServico, setSelectedServico] = useState(0);

    const dataAtual = new Date();
    const dataFormatada = dataAtual.toLocaleDateString('pt-BR');
    const [data,] = useState(dataFormatada);

    const acesso = localStorage.getItem('sessionChaveAcesso');
    const acessoContador = localStorage.getItem("sessionContador");
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

    useEffect(() => {
        (async () => {
            try {
                if (!selectedId) return;
                const data = await api.get("/nfse/" + selectedId, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (!data.data) return;
                if (data.data[0]) {
                    const obj = (data.data[0]);
                    const { id_emitente, id_cliente, id_servico, id_cidade, descricao, valor } = obj;
                    setValor(valor);
                    setDescricao(descricao);
                    setSelectedCidade(id_cidade === 0 ? "" : id_cidade);
                    setSelectedEmitente(id_emitente === 0 ? "" : id_emitente);
                    setSelectedCliente(id_cliente === 0 ? "" : id_cliente);
                    setSelectedServico(id_servico === 0 ? "" : id_servico);
                }
            } catch (e) {
                toast.error("Problema na rotina: ", e);
            }
        })();
    }, [selectedId]);

    // Select clientes
    useEffect(() => {
        if (!selectedEmitente) { return; }
        (async () => {
            try {
                const { data: clientes } = await api.get("/clientes/Todos/" + selectedEmitente, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (clientes) {
                    setClientes(clientes);
                }
            } catch (error) {
                toast.error(error);
            }
        })();
    }, [selectedEmitente]);

    // Select servicos
    useEffect(() => {
        (async () => {
            try {
                const { data: servicos } = await api.get("/servicos");
                if (servicos) {
                    setServicos(servicos);
                }
            } catch (error) {
                toast.error(error);
            }
        })();
    }, []);

    // Select cidades
    useEffect(() => {
        (async () => {
            try {
                const { data: cidades } = await api.get("/cidades");
                if (cidades) {
                    setCidades(cidades);
                }
            } catch (error) {
                toast.error(error);
            }
        })();
    }, []);

    // Select emitentes
    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const response = await api.get(`/emitentesAll/${acessoContador}/${acesso}`, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            const newToken = response.headers['newtoken']; 
            if (newToken) {
                localStorage.setItem('sessionToken', newToken);
                setTokenSession(newToken);
            }
            // Trabalha com os dados da resposta
            if (response.data) {
                setEmitentes(response.data);
            }
        } catch (error) {
            toast.error('Erro ao buscar token, faça login novamente :', error);
            navigate('/login');
        }
    };

    const handleClose = () => {
        setValor(0);
        setDescricao("");
        setSelectedCidade(0);
        setSelectedCliente(0);
        setSelectedEmitente(0);
        setSelectedServico(0);
        onCloseModalNova();
    }

    function formatDate(inputDate) {
        // Dividir a string de entrada no formato dd/mm/yyyy
        let [day, month, year] = inputDate.split('/');

        // Obter a data atual para extrair horas, minutos e segundos
        let currentDate = new Date();
        let hours = String(currentDate.getHours()).padStart(2, '0');
        let minutes = String(currentDate.getMinutes()).padStart(2, '0');
        let seconds = String(currentDate.getSeconds()).padStart(2, '0');

        // Retornar a data no novo formato yyyy-mm-dd HH:mm:ss
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const handleUpdate = async () => {
        if (selectedCliente !== "" && selectedEmitente !== "" && selectedCidade !== "" && selectedServico !== "" && descricao !== "") {
            try {
                const valorFormatado = parseFloat(valor.replace(',', '.'));
                const obj =
                {
                    id_emitente: selectedEmitente,
                    id_cliente: selectedCliente,
                    id_servico: selectedServico,
                    id_cidade: selectedCidade,
                    valor: valorFormatado,
                    descricao: descricao
                }
                await api.put("/updateNFSe/" + selectedId, obj, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                handleClose();
            } catch (error) {
                console.error('Erro ao alterar os dados:', error);
            }
        } else {
            toast.error("Todos os campos devem ser preenchidos..")
        }
    }

    const handleInserir = async () => {
        if (selectedCliente !== "" && selectedEmitente !== "" && selectedCidade !== "" && selectedServico !== "" && descricao !== "") {
            try {
                const valorFormatado = parseFloat(valor.replace(',', '.'));
                const obj =
                {
                    id_emitente: selectedEmitente,
                    id_cliente: selectedCliente,
                    id_servico: selectedServico,
                    id_cidade: selectedCidade,
                    numero: 123,
                    valor: valorFormatado,
                    descricao: descricao,
                    data: formatDate(data),
                    status: 'Pendente',
                    id_faturamento: 0
                }

                await api.post("/createNfse", obj, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                handleClose();
            } catch (error) {
                console.error('Erro ao incluir os dados:', error);
            }
        } else {
            toast.error("Todos os campos devem ser preenchidos..")
        }
    }

    const handleEmitente = (id) => {
        setSelectedEmitente(id);
        const current_emitente = emitentes.reduce((e) =>
            e.id === id
        )
        setSelectedServico(current_emitente.id_servico);
    }

    return (
        <div>
            <Dialog open={statusModal} onClose={handleClose} scroll="paper" fullWidth>
                <DialogTitle>Emissão de NFS-e</DialogTitle>
                <DialogContent className="dl-container-nfse" dividers>
                    <label>Emitente : </label>
                    <select className='select-item'
                        onChange={(e) => handleEmitente(e.target.value)}
                        required
                        value={selectedEmitente}
                    >
                        <option key='99999' value='99999'>Escolha um Emitente</option>
                        {
                            emitentes.map((emit) => (
                                <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                            ))
                        }
                    </select>
                </DialogContent>
                <hr></hr>
                <DialogContent className="dl-container-nfse" dividers>
                    <label>Cliente : </label>
                    <select className='select-item'
                        onChange={(e) => setSelectedCliente(e.target.value)}
                        required
                        value={selectedCliente}
                    >
                        <option key='99999' value='99999'>Escolha um Cliente</option>
                        {
                            clientes.map((cli) => (
                                <option key={cli.id} value={cli.id}>{cli.nome}</option>
                            ))
                        }
                    </select>
                </DialogContent>
                <hr></hr>
                <DialogContent>
                    <TextField
                        required
                        value={valor}
                        id="valor"
                        label="Valor do Serviço"
                        variant="filled"
                        onChange={(e) => setValor(e.target.value)}
                    />
                </DialogContent>
                <hr></hr>
                <DialogContent className="dl-container-nfse">
                    <label>Serviço : </label>
                    <select className='select-item'
                        onChange={(e) => setSelectedServico(e.target.value)}
                        required
                        value={selectedServico}
                    >
                        <option key='99999' value='99999'>Escolha um Serviço</option>
                        {
                            Array.isArray(servicos) && servicos.length > 0 ? (
                                servicos.map((cnae) => (
                                    <option key={cnae.id} value={cnae.id}>{cnae.codigo} - {cnae.descricao}</option>
                                ))
                            ) : (
                                <option>Carregando...</option>
                            )
                        }
                    </select>
                </DialogContent>
                <hr></hr>
                <DialogContent className="dl-container-nfse">
                    <label>Local  : </label>
                    <select className='select-item'
                        onChange={(e) => setSelectedCidade(e.target.value)}
                        required
                        value={selectedCidade}
                    >
                        <option key='99999' value='99999'>Escolha uma Cidade</option>
                        {
                            Array.isArray(cidades) && cidades.length > 0 ? (
                                cidades.map((cid) => (
                                    <option key={cid.codigo_ibge} value={cid.id}>{cid.nome}</option>
                                ))
                            ) : (
                                <option>Carregando...</option>
                            )
                        }
                    </select>
                </DialogContent>
                <hr></hr>
                <DialogContent className="dl-container-nfse" dividers>
                    <TextField
                        fullWidth
                        required
                        value={descricao}
                        id="descricao"
                        label="Descrição do serviço"
                        variant="filled"
                        // value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                    />
                </DialogContent>

                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Fechar</button>
                    {operacao === 'Editar' && (
                        <button className="btn-incluir" onClick={handleUpdate}>Alterar</button>
                    )}
                    {operacao !== 'Editar' && (
                        <button className="btn-incluir" onClick={handleInserir}>Gravar</button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default ModalNfse;