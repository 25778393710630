
import './Nfse.css';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import ModalNfse from '../Modal/Modal-Nfse.jsx';
import { Box } from '@mui/material';
import api from '../../api.js';
import ModalDialog from '../Modal/Modal-Dialog.jsx';
import ModalConfirmacao from '../Modal/Modal-Confirmacao.jsx';
import { toast } from 'react-toastify';
import Avatar from '@mui/material/Avatar';

const NFSe = () => {

    const dataAtual = new Date();
    const dataFormatada = dataAtual.toLocaleDateString('pt-BR');
    const [data,] = useState(dataFormatada);
    const apiRef = useGridApiRef();
    const date = new Date();
    const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const [selectedId, setSelectedId] = useState(null);
    const [idFaturamento, setIdFaturamento] = useState(0);

    const [openModalNova, setOpenModalNova] = useState(false);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);
    const [nfses, setNfses] = useState([]);
    const [primeiroDiaString, setPrimeiroDiaString] = useState(`${primeiroDia.getFullYear()}-${(primeiroDia.getMonth() + 1).toString().length < 2 ? `0${primeiroDia.getMonth() + 1}` : primeiroDia.getMonth() + 1}-${primeiroDia.getDate().toString().length < 2 ? `0${primeiroDia.getDate()}` : primeiroDia.getDate()}`);
    const [lastDayDate, setLastDayDate] = useState(`${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().length < 2 ? `0${lastDay.getMonth() + 1}` : lastDay.getMonth() + 1}-${lastDay.getDate().toString().length < 2 ? `0${lastDay.getDate()}` : lastDay.getDate()}`);

    const [total, setTotal] = useState(0);
    const [origem, setOrigem] = useState("");
    const [operacao, setOperacao] = useState("");
    const [titulo, setTitulo] = useState("");
    const [emitentes, setEmitentes] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState("Todas");

    const [selectedEmitente, setSelectedEmitente] = useState("");
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

    const acesso = localStorage.getItem('sessionChaveAcesso');
    const acessoContador = localStorage.getItem("sessionContador");

    // Select emitentes 
    useEffect(() => {
        (async () => {
            try {
                const { data } = await api.get("/emitentesAll/" + acessoContador + "/" + acesso, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (data) {
                    setEmitentes(data);
                    if (data.length > 0) {
                        setSelectedEmitente(data[0].id);
                    }
                }
            } catch (error) {
                alert(error);
            }
        })();
    }, []);

    function handleCloseModalNova() {
        setOpenModalNova(false);
        setSelectedId(null);
        setOperacao("");
        handleFiltro();
    }

    function handleOpenModalNova() {
        setOpenModalNova(true);
    }

    function handleCloseModalDialog() {
        setOpenModalDialog(false);
    }

    function handleOpenModalDialog(e, fat) {
        setSelectedId(e);
        setIdFaturamento(fat);
        setOrigem('NFSe');
        setTitulo('Confirma o envio da NFSe ? ');
        setOperacao('Enviar');
        setOpenModalDialog(true);
    }

    function handleOpenModalDialogValidar(e, referencia) {
        setSelectedId(e);
        setIdFaturamento(referencia);
        setOrigem('NFSe');
        setTitulo('Validar a nota na prefeitura ? ');
        setOperacao('Validar');
        setOpenModalDialog(true);
    }

    function handleOpenEnviarEmail(e, fat) {
        setSelectedId(e);
        setIdFaturamento(fat);
        setTitulo('Confirma o envio do email ?');
        setOrigem('NFSe');
        setOperacao('Email')
        setOpenModalDialog(true);
    }

    const onEnviarEmail = async (id) => {
        const obj = { "id": id };
        const email = await api.post('/nfseEmail', obj, {
            headers: {
                'Authorization': `Bearer ${tokenSession}`,
            }
        });
        toast.success(JSON.stringify(email.data));
        setOpenModalDialog(false);
    }

    const onValidarNFSe = async (id) => {
        // enviar para o backend validar 
        if (idFaturamento === 0) {
            toast.warning('NFSe não foi gerada ');
            return;
        }
        const validado = await api.get('/nfseIdPdf/' + idFaturamento, {
            headers: {
                'Authorization': `Bearer ${tokenSession}`,
            }
        });
        toast.warning(JSON.stringify(validado.data));
        setOpenModalDialog(false);
        handleFiltro();
    }

    const handleOpenModalConfirmacao = (e) => {
        setOrigem('NFSe');
        setOperacao('Excluir');
        setTitulo('Confirma a exclusão da NFSe ?')
        setSelectedId(e);
        setOpenModalConfirmacao(true);
    }

    const handleCloseModalConfirmacao = () => {
        setOpenModalConfirmacao(false);
        setOperacao("");
    }

    const handleEnviarNfse = async () => {
        setOpenModalDialog(false);
        try {
            const obj = { 'id': selectedId, 'idFaturamento': idFaturamento };
            const retorno = await api.post("/nfseEnviar", obj, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            toast.success(retorno);
            handleFiltro();
        } catch (error) {
            const errorMsg = error.response?.data?.message || error.response.data.error;            
            toast.error(errorMsg);
        }
    }

    const handleConsultarNFSe = async (id) => {
        try {
            const consulta = await api.get('/nfsePdf/' + id);
            window.open(consulta.data, '_blank'); // '_blank' abre em uma nova aba        
        } catch (error) {
            toast.error('Problema na rotina: ' + error);
        }
    }

    const handleEditarNFSe = (id) => {
        try {
            setSelectedId(id);
            setOperacao("Editar");
            setOpenModalNova(true);
        } catch (error) {
            alert(error);
        }
    }

    // const openRepetirNFSe = async (id) => {
    //     setOrigem('NFSe');
    //     setOperacao('Repetir');
    //     setTitulo('Confirma a replicação da NFSe ?')
    //     setSelectedId(id);
    //     setOpenModalConfirmacao(true);
    // }

    function formatDate(inputDate) {
        // Dividir a string de entrada no formato dd/mm/yyyy
        let [day, month, year] = inputDate.split('/');

        // Obter a data atual para extrair horas, minutos e segundos
        let currentDate = new Date();
        let hours = String(currentDate.getHours()).padStart(2, '0');
        let minutes = String(currentDate.getMinutes()).padStart(2, '0');
        let seconds = String(currentDate.getSeconds()).padStart(2, '0');

        // Retornar a data no novo formato yyyy-mm-dd HH:mm:ss
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const repeteNFSe = async (id) => {
        try {
            const nota = await api.get('/nfse/' + id, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            const obj =
            {
                id_emitente: nota.data[0].id_emitente,
                id_cliente: nota.data[0].id_cliente,
                id_servico: nota.data[0].id_servico,
                id_cidade: nota.data[0].id_cidade,
                numero: 123,
                valor: nota.data[0].valor,
                descricao: nota.data[0].descricao,
                data: formatDate(data),
                status: 'Pendente',
                id_faturamento: 0
            }
            const response = await api.post("/createNfse", obj, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            toast.success('Dados inseridos com sucesso:', response.data);
            handleFiltro();
        } catch (error) {
            toast.error('Erro ao incluir os dados:', error);
        }
    }

    const handleExcluirNFSe = async (id) => {
        await api.delete('/nfse/' + id, {
            headers: {
                'Authorization': `Bearer ${tokenSession}`,
            }
        });
        handleFiltro();
    }

    const columns = [
        {
            field: 'editar',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                params.row.status === "Enviada" ? (
                    <Avatar src={'https://img.icons8.com/fluency/48/edit-text-file.png'} alt=''
                        className='btn-ativa-nfse'
                        title='Editar'
                        align='center'
                        style={{ opacity: 0.3 }}
                    />
                ) : (
                    <Avatar src={'https://img.icons8.com/fluency/48/edit-text-file.png'} alt=''
                        className='btn-ativa-nfse'
                        align='center'
                        title='Editar'
                        onClick={() => handleEditarNFSe(params.id)}
                    />
                )
            )
        },
        { field: 'id_faturamento', headerName: 'idFat', flex: 0.5, maxWidth: 50 },
        { field: 'cliente', headerName: 'Cliente', flex: 1.5, maxWidth: 200 },
        { field: 'emitente', headerName: 'Emitente', flex: 1, maxWidth: 150 },
        {
            field: 'data',
            headerName: 'Data',
            headerAlign: 'center',
            flex: 0.5,
            type: 'date',
            maxWidth: 100,
            valueGetter: (value) => value && new Date(value)
        },
        {
            field: 'valor', headerName: 'Valor', flex: 0.5, type: 'number',
            align: 'center',
            maxWidth: 120,
            headerAlign: 'center',
            valueFormatter: (value) => {
                return new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                    minimumFractionDigits: 2,
                }).format(value);
            }
        },
        { field: 'status', headerName: 'Status', width: 80, flex: 0.3 },
        { field: 'email_enviado', headerName: 'e-mail', width: 50, flex: 0.3 },
        {
            field: 'email',
            align: 'center',
            headerName: '',
            flex: 0.3,
            width: 30,
            renderCell: (params) => (
                params.row.status === "Enviada" ? (
                    <Avatar src={'https://img.icons8.com/fluency/48/new-post.png'} alt=''
                        onClick={() => handleOpenEnviarEmail(params.id, params.row.status)}
                        className='btn-ativa-nfse'
                        title='Enviar a NFSe para o email do cliente'
                    />
                ) : (
                    <Avatar src={'https://img.icons8.com/fluency/48/new-post.png'} alt=''
                        className='btn-ativa-nfse'
                        style={{ opacity: 0.3, cursor: 'not-allowed' }}
                        title='NFSe ainda não foi gerada'
                    />
                )
            )
        },
        {
            field: 'enviar',
            flex: 0.3,
            headerName: '',
            width: 50,
            renderCell: (params) => (
                params.row.status === "Pendente" ? (
                    <Avatar src={'https://img.icons8.com/color/48/sent--v2.png'} alt=''
                        className='btn-ativa-nfse'
                        onClick={() => handleOpenModalDialog(params.id, params.row.id_faturamento)}
                        title='Enviar a NFSe para prefeitura'
                    />
                ) : (
                    <Avatar src={'https://img.icons8.com/color/48/sent--v2.png'} alt=''
                        className='btn-ativa-nfse'
                        style={{ opacity: 0.3, cursor: 'not-allowed' }}
                        title='NFSe já foi enviada para a prefeitura'
                    />
                )
            )

        },
        {
            field: 'validar',
            flex: 0.3,
            headerName: '',
            width: 50,
            renderCell: (params) => (
                params.row.status === "Processando" ? (
                    <Avatar src={'https://img.icons8.com/emoji/48/check-mark-emoji.png'} alt=''
                        className='btn-ativa-nfse'
                        onClick={() => handleOpenModalDialogValidar(params.id, params.row.referencia)}
                        title='Validar NFSe na prefeitura'
                    />
                ) : (
                    <Avatar src={'https://img.icons8.com/emoji/48/check-mark-emoji.png'} alt=''
                        className='btn-ativa-nfse'
                        style={{ opacity: 0.3, cursor: 'not-allowed' }}
                        title='NFSe já foi enviada para a prefeitura'
                    />
                )
            )

        },
        {
            field: 'consultar',
            flex: 0.3,
            headerName: '',
            width: 30,
            renderCell: (params) => (
                params.row.status === "Enviada" || params.row.status === "Processando" ? (
                    <Avatar src={'https://img.icons8.com/dusk/64/pdf.png'} alt=''
                        className='btn-ativa-nfse'
                        onClick={() => handleConsultarNFSe(params.id)}
                        title='Consultar a NFSe'
                    />
                ) : (
                    <Avatar src={'https://img.icons8.com/dusk/64/pdf.png'} alt=''
                        className='btn-ativa-nfse'
                        style={{ opacity: 0.3, cursor: 'not-allowed' }}
                        title='NFSe ainda não foi gerada'
                    />
                )
            )
        },
        {
            field: "cancelar",
            headerName: '',
            width: 30,
            flex: 0.3,
            renderCell: (params) => (
                params.row.status === "Enviada" ? (
                    <Avatar src={'https://img.icons8.com/ultraviolet/40/cancel.png'} alt=''
                        className='btn-ativa-nfse'
                        onClick={() => handleButtonCancelar(params.id)}
                        title='Cancelar a NFSe'
                    />
                ) : (
                    <Avatar src={'https://img.icons8.com/ultraviolet/40/cancel.png'} alt=''
                        className='btn-ativa-nfse'
                        style={{ opacity: 0.3, cursor: 'not-allowed' }}
                        title='Não é possivel cancelar a NFSe'
                    />
                )
            )
        },
        {
            field: "excluir",
            headerName: '',
            width: 30,
            flex: 0.3,
            renderCell: (params) => (
                params.row.status === "Pendente" ? (
                    <Avatar src={'https://img.icons8.com/plasticine/100/filled-trash.png'} alt=''
                        className='btn-ativa-nfse'
                        onClick={() => handleOpenModalConfirmacao(params.id, params.row.status)}
                        title='Excluir a NFSe'
                    />
                ) : (
                    <Avatar src={'https://img.icons8.com/plasticine/100/filled-trash.png'} alt=''
                        className='btn-ativa-nfse'
                        style={{ opacity: 0.3, cursor: 'not-allowed' }}
                        title='Não é possível excluir a NFSe'
                    />
                )
            )
        },
        {
            field: "log",
            headerName: '',
            width: 30,
            flex: 0.3,
            renderCell: (params) => (
                <Avatar src={'https://img.icons8.com/flat-round/50/question-mark.png'} alt=''
                    style={{padding:10}}
                    className='btn-ativa-nfse'
                    onClick={() => handleLog(params.row.logGeracao)}
                    title='Log Prefeitura'
                />
            )
        }
    ];

    const handleLog = (log) => {
        toast.warning(log);
    }

    // Select nfes
    useEffect(() => {
        (async () => {
            if (!selectedEmitente) { return; }
            try {
                const nfses = await api.get("/nfse/" + primeiroDiaString + "/" + lastDayDate + "/" + selectedStatus + "/" + selectedEmitente, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                const newToken = nfses.headers['newtoken'];
                if (newToken) {
                    localStorage.setItem('sessionToken', newToken);
                    setTokenSession(newToken);
                }
                if (nfses?.data) {
                    setNfses(nfses.data);
                    let iptot = 0;
                    nfses.data.map((nfe) => {
                        iptot = iptot + parseFloat(nfe.valor);
                        return iptot;
                    });
                    let numeroComDecimais = iptot.toFixed(2);
                    let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
                    setTotal(numeroFormatadoComDecimais);
                    const newToken = nfses.headers['newtoken'];
                    if (newToken) {
                        localStorage.setItem('sessionToken', newToken);
                        setTokenSession(newToken);
                    }
                }
            } catch (error) {
                toast.error('Problema na localização das NFSe: ' + error.message);
            }
        })();
    }, [primeiroDiaString, lastDayDate, selectedEmitente, selectedStatus]);

    const handleButtonCancelar = async (id) => {
        setOrigem('NFSe');
        setOperacao('Cancelar');
        setTitulo('Confirma o cancelamento da NFSe ?')
        setSelectedId(id);
        setOpenModalConfirmacao(true);
    }

    const onCancelarNFSe = async () => {
        try {
            const ret = await api.delete("/cancelarNfse/" + selectedId, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            toast.success(ret);
            handleFiltro();
        } catch (error) {
            toast.error(error);
        }

    }

    const handleFiltro = async (e) => {
        try {
            setNfses([]);
            const nfses = await api.get("/nfse/" + primeiroDiaString + "/" + lastDayDate + "/" + selectedStatus + "/" + selectedEmitente, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            if (nfses?.data) {
                setNfses(nfses.data);
                let iptot = 0;
                nfses.data.map((nfe) => {
                    iptot = iptot + parseFloat(nfe.valor);
                    return iptot;
                });
                const newToken = nfses.headers['newtoken'];
                if (newToken) {
                    localStorage.setItem('sessionToken', newToken);
                    setTokenSession(newToken);
                }
                let numeroComDecimais = iptot.toFixed(2);
                let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
                setTotal(numeroFormatadoComDecimais);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    function handlePrimeiraData(e) {
        const resultArray = e.substring(0, 10) + ' 00:00:01';
        setPrimeiroDiaString(resultArray);
    }

    function handleUltimaData(e) {
        const resultArray = e.substring(0, 10) + ' 23:59:59';
        setLastDayDate(resultArray);
    }

    const filtroStatus = ['Todas', 'Pendentes', 'Enviadas', 'Canceladas', 'Processando'];

    function handleSetStatus(e) {
        let st = 'Todas';
        if (e === "1") { st = 'Pendente'; }
        if (e === "2") { st = 'Enviada'; }
        if (e === "3") { st = 'Cancelada'; }
        if (e === "4") { st = 'Processando'; }
        setSelectedStatus(st)
        handleFiltro();
    }

    function getRowClassName(params) {
        const status = params.row.status;
        if (status === 'Pendente') {
            return 'linha-pendente'; 
        } else if (status === 'Cancelada') {
            return 'linha-cancelada'; 
        } else if (status === 'Enviada') {
            return 'linha-enviada'; 
        } else if (status === 'Processando') {
            return 'linha-processando'; 
        } else {
            return '';
        }
    }

    return (
        <div className='container-nfse'>
            <ModalNfse statusModal={openModalNova} onCloseModalNova={handleCloseModalNova} selectedId={selectedId} operacao={operacao} ></ModalNfse>
            <ModalDialog
                statusModal={openModalDialog}
                onCloseModalDialog={handleCloseModalDialog}
                onEnviarNFSe={handleEnviarNfse}
                selectedId={selectedId}
                titulo={titulo}
                origem={origem}
                operacao={operacao}
                onEnviarEmail={onEnviarEmail}
                onValidar={onValidarNFSe}>
            </ModalDialog>
            <ModalConfirmacao statusModal={openModalConfirmacao} onCloseModalConfirmacao={handleCloseModalConfirmacao} onExcluirNFSe={handleExcluirNFSe} onRepetirNFSe={repeteNFSe} onCancelarNFSe={onCancelarNFSe} selectedId={selectedId} titulo={titulo} origem={origem} operacao={operacao}></ModalConfirmacao>
            <div className="container-top-nfse">
                <div className="column-nfse-top">
                    <h2 style={{ color: '#fff' }} >Gestão de Notas Fiscais</h2>
                </div>
                <div className="btn-nfse-novo">
                    <button onClick={handleOpenModalNova}>Nova NFS-e</button>
                </div>
            </div>
            <div className="nfse-filtro">
                <h2 style={{ padding: 10, color: '#fff' }}>Filtros: </h2>
                <TextField
                    id="datetime-inicio"
                    label="Inicial"
                    type="date"
                    defaultValue={primeiroDiaString}
                    onChange={(e) => handlePrimeiraData(e.target.value)}
                    variant="filled"
                    className="nfse-custom-textfield"
                    InputLabelProps={{
                        shrink: true,
                        style: { color: 'white' }, // Cor do label
                    }}
                    InputProps={{
                        style: { color: 'white' }, // Cor do texto
                    }}

                />
                <span><h3 style={{ padding: 10, color: '#fff' }}>até</h3> </span>
                <TextField
                    id="datetime-final"
                    label="Final"
                    variant="filled"
                    type="date"
                    defaultValue={lastDayDate}
                    onChange={(e) => handleUltimaData(e.target.value)}
                    className="nfse-custom-textfield"
                    InputLabelProps={{
                        shrink: true,
                        style: { color: 'white' }, // Cor do label
                    }}
                    InputProps={{
                        style: { color: 'white' }, // Cor do texto
                    }}
                />

                <select className='btn-filtrar-fat' style={{ width: 200, border: 'none' }}
                    onChange={(e) => setSelectedEmitente(e.target.value)}
                    required
                    value={selectedEmitente}
                >
                    <option value="">Selecione um emitente</option>
                    {
                        Array.isArray(emitentes) && emitentes.length > 0 ? (
                            emitentes.map((emit) => (
                                <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                            ))
                        ) : (
                            <option value="">Nenhum emitente disponível</option>
                        )
                    }
                </select>

                <select className='btn-filtrar-nfe'
                    onChange={(e) => handleSetStatus(e.target.value)}
                    required
                >
                    {
                        filtroStatus.map((number, index) => (
                            <option key={index} value={index}>{number}</option>
                        ))
                    }
                </select>
                <button className='btn-filtrar-nfe' onClick={handleFiltro} >Filtrar</button>

                <div className='div-total'>
                    <h2 style={{ color: '#fff' }}>Total do filtro: R$ {total}</h2>
                </div>
            </div>
            <div className="nfse-table">
                <Box sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                    <DataGrid
                        autoHeight
                        rows={nfses ?? []}
                        columns={columns}
                        localeText={{
                            toolbarDensity: 'Tamanho',
                            toolbarColumns: 'Colunas',
                            toolbarExport: 'Exportar',
                            footerRowSelected: (count) =>
                                count !== 1 ? `${count.toLocaleString()} linhas selecionadas` : `${count.toLocaleString()} linha selecionada`,
                            footerTotalRows: 'Total de linhas:',
                            MuiTablePagination: {
                                labelRowsPerPage: 'Linhas por página:',
                            },
                        }}
                        getRowClassName={getRowClassName}
                        disableRowSelectionOnClick
                        disableColumnResize={true}
                        apiRef={apiRef} // Passando o apiRef para o DataGrid
                        initialState={{
                            columns: {
                                columnVisibilityModel: {
                                    id_faturamento: false                                    
                                },
                            },
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                    />
                </Box>
                {/* <NotaFiscalTable notas={nfses} /> */}
            </div>
        </div>
    );
}

export default NFSe;