import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Select, MenuItem } from "@mui/material";
import './Modal-dialog.css';
// import { useState } from "react";

function ModalDialog({ statusModal, onCloseModalDialog, onValidar, onEnviarNFSe, onRepetirNFSe, statusEmitente, excluirEmpresa, ativarEmpresa, onBaixarImposto, onExcluirImposto, selectedId, titulo, origem, operacao, onEnviarEmail }) {

    const handleClose = () => {
        onCloseModalDialog();
    }

    const handleEnviar = () => {
        onEnviarNFSe();
    }

    const handleRepetir = () => {
        onRepetirNFSe(selectedId);
    }

    const handleAtivarEmpresa = () => {
        ativarEmpresa();
        handleClose();
    }

    const handleExcluirEmpresa = () => {
        excluirEmpresa();
        handleClose();
    }

    const handleStatusEmpresa = () => {
        statusEmitente();
        handleClose();
    }

    const handleBaixarImposto = () => {
        onBaixarImposto();
    }

    const handleExcluirImposto = () => {
        onExcluirImposto();
    }

    const handleEnviarEmail = () => {
        onEnviarEmail(selectedId);
    }

    const handleValidarNFSe = () => {
        onValidar(selectedId);
        handleClose();
    }

    return (
        <div>
            <Dialog className="d-container" open={statusModal} onClose={handleClose} scroll="paper" fullWidth maxWidth="lg">
                <DialogTitle>{titulo}</DialogTitle>
                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Sair</button>
                    {origem === 'NFSe' && operacao === 'Enviar' && (
                        <button className="btn-incluir" onClick={handleEnviar}>Enviar</button>
                    )}
                    {origem === 'RepetirNFSe' && (
                        <button className="btn-incluir" onClick={handleRepetir}>Repetir</button>
                    )}
                    {origem === 'Emitente' && operacao === 'Habilitar' && (
                        <button className="btn-incluir" onClick={handleAtivarEmpresa}>Ativar Empresa'</button>
                    )}
                    {origem === 'Emitente' && operacao === 'Exclusao' && (
                        <button className="btn-incluir" onClick={handleExcluirEmpresa}>Excluir Empresa'</button>
                    )}
                    {origem === 'Emitente' && operacao !== 'Habilitar' && operacao !== 'Exclusao' && (
                        <button className="btn-incluir" onClick={handleStatusEmpresa}>{operacao}</button>
                    )}
                    {origem === 'Imposto' && operacao != 'Excluir' && (
                        <button className="btn-incluir" onClick={handleBaixarImposto}>Baixar'</button>
                    )}
                    {origem === 'Imposto' && operacao === 'Excluir' && (
                        <button className="btn-incluir" onClick={handleExcluirImposto}>Excluir'</button>
                    )}
                    {origem === 'NFSe' && operacao === 'Email' && (
                        <button className="btn-incluir" onClick={handleEnviarEmail}>Enviar email</button>
                    )}
                     {origem === 'NFSe' && operacao === 'Validar' && (
                        <button className="btn-incluir" onClick={handleValidarNFSe}>Validar</button>
                    )}
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ModalDialog;