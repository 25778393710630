import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import './modal-compromissos.css';
import { useState } from "react";


function ModalCompromissos({ statusModel, onCloseModal, rows, setRows }) {

    const [descricao, setDescricao] = useState("");
    const dataAtual = new Date();
    const dataFormatada = dataAtual.toLocaleDateString('pt-BR');
    const [data, setData] = useState(dataFormatada);

    const handleClose = () => {
        setDescricao("");
        onCloseModal();
    }

    const handleDescricao = (e) => {
        setDescricao(e);
    }

    const handleData = (e) => {
        setData(e);
    }

    const handleInserir = () => {
        if (descricao != "") {
            const newRows = JSON.parse(JSON.stringify(rows));
            newRows.push({ id: newRows.length + 1, data: '01/01/2020', desc: descricao, status: 'pendente' });
            setRows(newRows);
            handleClose();
        } else { alert("Descrição não preenchida") }
    }

    return (
        <div>
            <Dialog className="d-container" open={statusModel} onClose={handleClose} scroll="paper" fullWidth>
                <DialogTitle>Lançamento de um novo compromisso</DialogTitle>
                <DialogContent className="dl-container" dividers>
                    <TextField
                        required
                        id="descricao"
                        label="Descrição"
                        variant="standard"
                        value={descricao}
                        onChange={(e) => handleDescricao(e.target.value)}
                    />
                </DialogContent>
                <DialogContent className="dl-container" dividers>
                    <TextField
                        required
                        id="data"
                        label="Data"
                        variant="standard"
                        value={data}
                        onChange={(e) => handleData(e.target.value)}
                    />
                </DialogContent>

                <DialogActions>
                    <button className="btn-fechar" onClick={handleClose}>Fechar</button>
                    <button className="btn-incluir" onClick={handleInserir}>inserir</button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default ModalCompromissos;