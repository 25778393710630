import { React, useState, useEffect } from 'react';
// import { PieChart, Pie, ResponsiveContainer, Cell } from 'recharts';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Cell } from "recharts";

import api from '../../api.js';
import { toast } from 'react-toastify';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, name }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="#000" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${name} ${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ChartNFSe = (ano) => {
  const [fMensal, setFMensal] = useState([]);
  const [totalFat, setTotalFat] = useState(0);
  const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

  useEffect(() => {
    (async () => {
      try {
        if (!ano.ano) { return; }
        const { data: fatMensal } = await api.get('/nfseMensal/' + ano.ano, {
          headers: {
            'Authorization': `Bearer ${tokenSession}`,
          }
        });
        if (fatMensal && fatMensal.length > 0) {
          // Convertendo 'value' para float e mantendo o nome do mês
          const formattedData = fatMensal.map((item) => ({
            ...item,
            value: parseFloat(item.value)
          }));
          setFMensal(formattedData);
          // Totalizando os valores
          const total = formattedData.reduce((acc, item) => acc + item.value, 0);
          setTotalFat(total); // Definindo o total no estado
        } else {
          setTotalFat(0);
          setFMensal([{ name: '0', label: '0', value: 1 }]);
        }
      } catch (error) {
        toast.error(error.message || 'Erro ao carregar dados');
      }
    })();
  }, [ano]);

  return (
    <>

      <div>
        <h3 style={{ display: 'flex', justifyContent: 'center' }}>NFSe anual: R$ {totalFat.toFixed(2)}</h3>
      </div>

      <ResponsiveContainer width="100%" height="100%">
        <div
          style={{
            display: "flex",
            textAlign: "center",
            transform: "translate(30%, -10%)",
            fontSize: "1.3vw", // Ajuste responsivo com viewport width
            maxWidth: "100%", // Garante que o conteúdo não ultrapasse o contêiner
            whiteSpace: "nowrap", // Previne quebra de linha em telas menores

          }}
        >
        </div>

        <BarChart
          data={fMensal}
          width={450}
          height={330}
          margin={{
            top: 10,
            right: 20,
            left: 20,
            bottom: 20,
          }}
        >
          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis dataKey="label" />
          {/* <YAxis /> */}
          <Tooltip />
          <Bar dataKey="value" fill="#8884d8">
            {fMensal.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

export default ChartNFSe;
