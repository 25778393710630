import "./App.css";
import 'react-toastify/dist/ReactToastify.css';
import Rotas from "./routes.jsx";
/*import './index.css';*/

function App() {
  return (
    <div className="App">
      <Rotas />
    </div>
  );
}

export default App;
