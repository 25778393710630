import './Clientes.css';
import { DataGrid, useGridApiRef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import ModalCliente from '../Modal/Modal-Cliente.jsx';
import ModalDialog from '../Modal/Modal-Dialog.jsx';
import api from '../../api.js';
import { Box } from '@mui/material';
import ModalConfirmacao from '../Modal/Modal-Confirmacao.jsx';
import ModalClienteServico from '../Modal/Modal-cliente-servico.js';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';

function Clientes() {
    const navigate = useNavigate();
    const [openModalNova, setOpenModalNova] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [cliente, setCliente] = useState([]);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);
    const [openModalServicos, setOpenModalServicos] = useState(false);
    const [status, setStatus] = useState('Ativo');
    const apiRef = useGridApiRef();
    const [selectedEmitente, setSelectedEmitente] = useState(0);
    const [emitente, setEmitente] = useState([]);
    const [operacao, setOperacao] = useState('');

    const acesso = localStorage.getItem('sessionChaveAcesso');
    const acessoContador = localStorage.getItem("sessionContador");
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

    const columns = [
        {
            field: 'editar',
            headerName: '',
            width: 60,
            renderCell: (params) => (
                <Avatar src={'https://img.icons8.com/fluency/48/edit-text-file.png'} alt=''
                    className='btn-ativa-pagar'
                    onClick={() => handleButtonEditar(params.id)}
                    title='Editar'
                />
            )
        },
        { field: 'cnpjcpf', headerName: 'Cnpj/Cpf', width: 140 },
        { field: 'razao', headerName: 'Razao', width: 300, flex: 1, },
        { field: 'nome', headerName: 'Nome', width: 250, flex: 0.8, },
        { field: 'nome_cidade', headerName: 'Cidade', width: 100, flex: 0.5 },
        { field: 'nome_estado', headerName: 'UF', width: 80, flex: 0.5 },
        { field: 'ativo', headerName: 'Ativo', width: 10, flex: 0.3, disabled: true },
        { field: 'ultima_nota_emitida', headerName: 'Última NFSe', width: 100, flex: 0.4, editable: false },
        {
            field: 'servico',
            headerName: '',
            width: 120,
            renderCell: (params) => (
                <button className='btn-servico' onClick={() => handleOpenServicos(params.id)}>
                    Serviços
                </button>
            )
        },
        {
            field: 'repetir',
            headerName: '',
            width: 80,
            align: 'center',
            renderCell: (params) => (
                params.row.ultima_nota_emitida === "" || params.row.ultima_nota_emitida === null ? (
                    <Avatar src={'https://img.icons8.com/cute-clipart/64/repeat.png'} alt=''
                        className='btn-ativa-pagar'
                        title='Repetir NFSe'
                        style={{ opacity: 0.3 }}
                    />
                ) : (
                    <Avatar src={'https://img.icons8.com/cute-clipart/64/repeat.png'} alt=''
                        className='btn-ativa-pagar'
                        onClick={() => handleRepetir(params.id)}
                        title='Repetir NFSe'
                    />                    
                )

            )
        },
        {
            field: 'deletar',
            headerName: '',
            width: 80,
            renderCell: (params) => (
                <Avatar src={'https://img.icons8.com/plasticine/100/filled-trash.png'} alt=''
                    className='btn-ativa-pagar'
                    onClick={() => handleButtonExcluir(params.id)}
                    title='Excluir'
                />
            )
        }
    ];

    const fetchEmitentes = async () => {
        try {
            const response = await api.get(`/emitentesAll/${acessoContador}/${acesso}`, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            const newToken = response.headers['newtoken'];
            if (newToken) {
                localStorage.setItem('sessionToken', newToken);
                setTokenSession(newToken);
            }
            // Trabalha com os dados da resposta
            if (response.data) {
                setEmitente(response.data);
                if (response.data.length > 0) {
                    setSelectedEmitente(response.data[0].id);
                }
            } else { setSelectedEmitente(0); }
        } catch (error) {
            toast.error('Erro ao buscar token, faça login novamente :', error);
            navigate('/login');
        }
    };

    // Select emitentes
    useEffect(() => {
        fetchEmitentes();
    }, []);

    const onExcluir = (async (id) => {
        try {
            const temNota = await api.get("/clienteNota/" + id,{
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            if (temNota.data !== '0') {
                toast.warning('Cliente possui NFSe ou Faturamento, não pode ser excluido.');
            } else {
                const exclui = await api.delete("/cliente/" + id,{
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                toast.success(exclui.data);
                atualizaTela();
            }
        } catch (error) {
            toast.error('Erro:' + error);
        }
    })

    const handleButtonExcluir = (async (id) => {
        setSelectedId(id);
        setOperacao('Excluir');
        setOpenModalConfirmacao(true);
    })

    function handleButtonEditar(id) {
        setSelectedId(id);
        setOpenModalNova(true);
    }

    function handleRepetir(id) {
        setSelectedId(id);
        setOpenModalDialog(true);
    }

    const handleRepetirNfse = async (id) => {
        setOpenModalDialog(false);
        try {
            const repetiNota = await api.get("/clienteNota/" + selectedId);
            if (repetiNota.status === 200) {
                const repetir = await api.put("/repetirNFSe/" + repetiNota.data);
                if (repetir.status === 200) {
                    alert('Nota replicada com sucesso.')
                } else {
                    alert('Problema na replicação: ' + repetir.data);
                }
            }
            //alert(repetir.data);
            //handleFiltro();
        } catch (error) {
            alert(error);
        }
    }

    function handleCloseModalNova() {
        setSelectedId(null);
        setOpenModalNova(false);
        atualizaTela();
    }

    function handleOpenModalNova() {
        setOpenModalNova(true);
    }

    const atualizaTela = () => {
        (async () => {
            try {
                if (status === '0') { setStatus('Ativo') };
                if (status === '1') { setStatus('Inativo') };
                if (status === '2') { setStatus('Todos') };
                const { data } = await api.get("/clientes/" + status + "/" + selectedEmitente,{
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (data)
                    setCliente(data);
            } catch (e) {
                toast.error("Problema na rotina: " + e);
            }
        })();
    }

    useEffect(() => {
        if (!selectedEmitente) return;
        (async () => {
            try {
                if (status === '0') { setStatus('Ativo') };
                if (status === '1') { setStatus('Inativo') };
                if (status === '2') { setStatus('Todos') };
                const data = await api.get("/clientes/" + status + "/" + selectedEmitente, {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                const newToken = data.headers['newtoken'];
                if (newToken) {
                    localStorage.setItem('sessionToken', newToken);
                    setTokenSession(newToken);
                }
                if (data.data) {
                    setCliente(data.data);
                }
            } catch (e) {
                toast.error("Problema na rotina: " + e);
            }
        })();
    }, [status, selectedEmitente]);

    function handleCloseModalDialog() {
        setOpenModalDialog(false);
    }

    function handleCloseModalConfirmacao() {
        setOpenModalConfirmacao(false);
    }

    function handleOpenServicos(id) {
        setSelectedId(id);
        setOpenModalServicos(true);
    }

    function handleCloseModalServicos() {
        setSelectedId(null);
        setOpenModalServicos(false);
    }

    const filtroStatus = ['Ativos', 'Inativos', 'Todos'];

    const handleSetStatus = (st) => {
        setStatus(st);
        if (st === "Ativos") { setStatus('Ativo'); }
        if (st === "Inativos") { setStatus('Inativo'); }
    }

    function getRowClassName(params) {
        const status = params.row.ativo; // Suponha que a coluna "status" contenha o status da linha
        if (status === 'S') {
            return 'linha-ativo'; // Classe CSS para linhas aprovadas
        } else if (status === 'N') {
            return 'linha-inativo'; // Classe CSS para linhas pendentes
        } else {
            return ''; // Classe padrão para outras linhas
        }
    }

    return (
        <div className="container-clientes">
            <ModalCliente statusModal={openModalNova} onCloseModal={handleCloseModalNova} selectedId={selectedId} ></ModalCliente>
            <ModalClienteServico statusModal={openModalServicos} onCloseModal={handleCloseModalServicos} selectedId={selectedId}></ModalClienteServico>
            <ModalDialog statusModal={openModalDialog} onCloseModalDialog={handleCloseModalDialog} onRepetirNFSe={handleRepetirNfse} selectedId={selectedId} titulo='Confirma geração dessa NFSe ?' origem='RepetirNFSe'></ModalDialog>
            <ModalConfirmacao statusModal={openModalConfirmacao} onCloseModalConfirmacao={handleCloseModalConfirmacao} onExcluir={onExcluir} selectedId={selectedId} titulo='Confirma a exclusão' origem='Clientes' operacao={operacao}></ModalConfirmacao>
            <div className="cli-container-top">
                <div className="cli-column-top">
                    <h2 style={{ color: '#fff' }} >Cadastro de Clientes</h2>
                </div>               
            </div>
            <div className='div-filtro-clientes'>
                <label>Filtrar por :</label>
                <select className='filtrar-cliente'
                    onChange={(e) => handleSetStatus(e.target.value)}
                    required
                >
                    {
                        filtroStatus.map((number, index) => (
                            <option key={index} value={index}>{number}</option>
                        ))
                    }
                </select>
                <select className='filtrar-cliente' style={{ width: 200, border: 'none' }}
                    onChange={(e) => setSelectedEmitente(e.target.value)}
                    required
                    value={selectedEmitente ? selectedEmitente : ''}
                >
                    <option value="">Selecione um emitente</option>
                    {
                        Array.isArray(emitente) && emitente.length > 0 ? (
                            emitente.map((emit) => (
                                <option key={emit.id} value={emit.id}>{emit.nome} - {emit.cod_tributario_municipio}</option>
                            ))
                        ) : (
                            <option value="">Nenhum emitente disponível</option>
                        )
                    }
                </select>
                <div className="btn-novo-cliente">
                    <button onClick={handleOpenModalNova}>Novo</button>
                </div>
            </div>

            <div className="cliente-meio">
                <Box sx={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
                    <DataGrid
                        autoHeight
                        rows={cliente ?? []}
                        columns={columns}
                        getRowClassName={getRowClassName}
                        apiRef={apiRef} // Passando o apiRef para o DataGrid
                        // checkboxSelection // Habilita a seleção por checkboxes
                        initialState={{
                            pagination: {
                                paginationModel: { page: 0, pageSize: 10 },
                            },
                        }}
                        pageSizeOptions={[5, 10, 20, 50]}
                        disableRowSelectionOnClick={true} // Permite a seleção ao clicar na linha
                    // rowSelectionModel={['id']} // Substitua por um array com os IDs das linhas que deseja selecionar
                    />
                </Box>
            </div>
        </div>
    );
}

export default Clientes;