import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Home from "./pages/Home/home.jsx";
// import PriveteRoute from "./components/Private-route/private-route.jsx";
import Login from "./pages/Login/login.jsx";
import { React, createContext, useState } from 'react';

export const AuthContext = createContext();

function Rotas() {

    const [user, setUser] = useState(null);

    return (
        <AuthContext.Provider value={{ user, setUser }}>
            <BrowserRouter>
                <Routes>
                    <Route path="/home" element={<Home />}></Route>
                    <Route path="/login" element={<Login />}></Route>
                    <Route path="/" element={<Login />}></Route>
                </Routes>
                <ToastContainer autoClose="2000" />
            </BrowserRouter>
        </AuthContext.Provider>
    )

    // return <BrowserRouter>
    //     <Routes>
    //         {/* <Route path="/home" element={<PriveteRoute><Home /></PriveteRoute>}></Route> */}
    //         <Route path="/home" element={<Home />}></Route>
    //         <Route path="/login" element={<Login />}></Route>
    //         <Route path="/" element={<Login />}></Route>
    //     </Routes>
    //     <ToastContainer autoClose="2000" />
    // </BrowserRouter>
}

export default Rotas ;