
import './Impostos.css';
import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import api from '../../api.js';
import ModalDialog from '../Modal/Modal-Dialog.jsx';
import ModalImposto from '../Modal/Modal-Imposto.jsx';
import { color, styled } from '@mui/system';
import { toast } from 'react-toastify';
import Avatar from '@mui/material/Avatar';
import ModalUpload from '../Impostos/UploadArquivo.jsx';

const Impostos = () => {

    const date = new Date();
    const primeiroDia = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const [primeiroDiaString, setPrimeiroDiaString] = useState(`${primeiroDia.getFullYear()}-${(primeiroDia.getMonth() + 1).toString().length < 2 ? `0${primeiroDia.getMonth() + 1}` : primeiroDia.getMonth() + 1}-${primeiroDia.getDate().toString().length < 2 ? `0${primeiroDia.getDate()}` : primeiroDia.getDate()}`);
    const [lastDayDate, setLastDayDate] = useState(`${lastDay.getFullYear()}-${(lastDay.getMonth() + 1).toString().length < 2 ? `0${lastDay.getMonth() + 1}` : lastDay.getMonth() + 1}-${lastDay.getDate().toString().length < 2 ? `0${lastDay.getDate()}` : lastDay.getDate()}`);
    const [openModalNova, setOpenModalNova] = useState(false);
    const [total, setTotal] = useState(0);
    const [filtro, setFiltro] = useState("1");
    const [impostos, setImpostos] = useState([]);
    const [openModalDialog, setOpenModalDialog] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [operacao, setOperacao] = useState("");
    const [origem, setOrigem] = useState("");
    const [titulo, setTitulo] = useState("");
    const [openModalUpload, setOpenModalUpload] = useState(false);
    const [tokenSession, setTokenSession] = useState(localStorage.getItem('sessionToken'));

    const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
        '& .row-color-red': {
            backgroundColor: 'red',
            color: 'white',
            '&:hover': {
                backgroundColor: 'darkred',
            },
        },
        '& .row-color-green': {
            backgroundColor: 'rgb(162, 243, 159)',
            color: 'rgb(255, 255, 255)',
            '&:hover': {
                backgroundColor: 'rgb(51, 67, 100)',
                color: '#fff'
            },
        },
        '& .row-color-white': {
            backgroundColor: 'white',
            color: 'white',
            '&:hover': {
                backgroundColor: 'rgb(51, 67, 100)',
            },
        },
        '& .cell-color-blue': {
            backgroundColor: 'blue',
            color: 'white',
            '&:hover': {
                backgroundColor: 'darkblue',
            },
        },
        '& .Mui-selected': {
            //   backgroundColor: '#fff !important',
            //   color: '#000 !important',
        }
    }));

    function handleCloseModalNova() {
        setOpenModalNova(false);
        handleFiltro();
    }

    function handleOpenModalNova() {
        setOpenModalNova(true);
    }

    const handleEditarImposto = (id) => {
        try {
            setSelectedId(id);
            setOperacao("Editar");
            setOpenModalNova(true);
        } catch (error) {
            toast.error(error);
        }
    }

    const columns = [
        {
            field: 'editar',
            headerName: '',
            width: 50,
            renderCell: (params) => (
                params.row.status !== "Pendente" ? (
                    <Avatar src={'https://img.icons8.com/fluency/48/edit-text-file.png'} alt=''
                        className='btn-ativa-pagar'
                        style={{ opacity: 0.3 }}
                        title='Excluir'
                    />
                ) : (
                    <Avatar src={'https://img.icons8.com/fluency/48/edit-text-file.png'} alt=''
                        className='btn-ativa-pagar'
                        onClick={() => handleEditarImposto(params.id)}
                        title='Excluir'
                    />
                )
            )
        },
        { field: 'id', headerName: 'ID', width: 70 },
        {
            field: 'data',
            headerName: 'Data',
            width: 100,
            agn: "center",
            type: 'date',
            valueGetter: (value) => value && new Date(value)
        },
        { field: 'descricao', headerName: 'Descricao', width: 300 },
        { field: 'valor', headerName: 'Valor', width: 120 },
        { field: 'status', headerName: 'Status', width: 120 },
        {
            field: 'caminho_http_doc',
            headerName: 'HTTPS',
            width: 180,
            renderCell: (params) => (
                params.value ? (
                    <a
                        href={params.value}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'blue', textDecoration: 'underline' }}
                    >
                        Visualizar Documento
                    </a>
                ) : null
            )
        },
        {
            field: 'baixar',
            headerName: '-',
            width: 100,
            renderCell: (params) => (
                <button className='btn-enviar'
                    style={{ display: params.row.status === 'Pago' ? 'none' : 'inline-block' }}
                    onClick={() => handleOpenModalDialog(params.id)}>
                    Baixar
                </button>
            )
        },
        {
            field: "excluir",
            headerName: '-',
            width: 100,
            renderCell: (params) => (
                <button className='btn-cancelar'
                    style={{ display: params.row.status === 'Pago' ? 'none' : 'inline-block' }}
                    onClick={() => handleButtonExcluir(params.id, params)}>
                    Excluir
                </button>
            )
        },
        {
            field: "pdf",
            headerName: '-',
            width: 100,
            renderCell: (params) => (
                <button className='btn-pdf' onClick={() => handleOpenModalUpload(params.id)}>
                    DOC
                </button>
            )
        }
    ];

    const handleFecharModalUpload = () => {
        setSelectedId("");
        setOpenModalUpload(false);
        toast.success('Upload feito com sucesso.')
    }

    // Select impostos
    useEffect(() => {
        (async () => {
            try {
                const { data: impostos } = await api.get("/impostos/" + primeiroDiaString + "/" + lastDayDate + "/Pendente", {
                    headers: {
                        'Authorization': `Bearer ${tokenSession}`,
                    }
                });
                if (impostos) {
                    setImpostos(impostos);
                    let iptot = 0;
                    impostos.map((imp) => {
                        iptot = iptot + parseFloat(imp.valor);
                    });
                    let numeroComDecimais = iptot.toFixed(2);
                    let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
                    setTotal(numeroFormatadoComDecimais);
                }
            } catch (error) {
                alert(error);
            }
        })();
    }, [setFiltro]);


    function handleOpenModalDialog(e) {
        setSelectedId(e);
        setOperacao("Baixa");
        setOrigem("Imposto");
        setTitulo("Confirma a baixa do imposto ?");
        setOpenModalDialog(true);
    }

    function handleButtonExcluir(event, st) {
        setSelectedId(event);
        setOperacao("Excluir");
        setOrigem("Imposto");
        setTitulo("Confirma a exclusão do imposto ?");
        setOpenModalDialog(true);
    }

    const handleFiltro = async (e) => {
        let status = "Todos";
        if (filtro === "1") {
            status = "Pendente"
        }
        if (filtro === "2") {
            status = "Pago"
        }
        try {
            const { data: impostos } = await api.get("/impostos/" + primeiroDiaString + "/" + lastDayDate + "/" + status, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            if (impostos) {
                setImpostos(impostos);
                let iptot = 0;
                impostos.map((imp) => {
                    iptot = iptot + parseFloat(imp.valor);
                });
                let numeroComDecimais = iptot.toFixed(2);
                let numeroFormatadoComDecimais = numeroComDecimais.toLocaleString('pt-BR');
                setTotal(numeroFormatadoComDecimais);
            }
        } catch (error) {
            alert(error);
        }
    }

    function handlePrimeiraData(e) {
        const resultArray = e.substring(0, 10) + ' 00:00:01';
        setPrimeiroDiaString(resultArray);
    }

    function handleUltimaData(e) {
        const resultArray = e.substring(0, 10) + ' 23:59:59';
        setLastDayDate(resultArray);
    }

    const filtroStatus = ['Todos', 'Pendentes', 'Pagos'];

    function handleCloseModalDialog() {
        setOpenModalDialog(false);
    }

    const handleBaixarImposto = async () => {
        setOpenModalDialog(false);
        try {
            await api.put("/imposto/" + selectedId + "/Pago");
            handleFiltro();
        } catch (error) {
            alert(error);
        }
    }

    const handleExcluirImposto = async () => {
        setOpenModalDialog(false);
        try {
            await api.delete("/imposto/" + selectedId, {
                headers: {
                    'Authorization': `Bearer ${tokenSession}`,
                }
            });
            toast.success('Processo concluido com sucesso.');
            handleFiltro();
        } catch (error) {
            toast.error('Problema na rotina: ' + error);
        }
        setSelectedId("");
    }

    const handleOpenModalUpload = (id) => {
        setSelectedId(id);
        setOpenModalUpload(true);
    }

    function getRowClassName(params) {
        const status = params.row.status; // Suponha que a coluna "status" contenha o status da linha
        if (status === 'Pendente') {
            return 'linha-pendente'; // Classe CSS para linhas aprovadas
        } else if (status === 'Pago') {
            return 'linha-cancelada'; // Classe CSS para linhas pendentes
        } else if (status === 'Enviada') {
            return 'linha-enviada'; // Classe CSS para linhas pendentes
        } else {
            return ''; // Classe padrão para outras linhas
        }
    }

    return (
        <div className='container-impostos'>
            <ModalImposto statusModal={openModalNova} onCloseModal={handleCloseModalNova} selectedId={selectedId}></ModalImposto>
            <ModalDialog statusModal={openModalDialog} onCloseModalDialog={handleCloseModalDialog} onBaixarImposto={handleBaixarImposto} onExcluirImposto={handleExcluirImposto} selectedId={selectedId} titulo={titulo} origem={origem} operacao={operacao}></ModalDialog>
            <ModalUpload statusModal={openModalUpload} onCloseModal={handleFecharModalUpload} selectedId={selectedId} ></ModalUpload>
            <div className="imposto-top">
                <div className="imposto-column-top">
                    <h2 style={{ color: '#fff' }} >Relação de Impostos</h2>
                </div>
                <div className="imposto-column-top-btn">
                    <button onClick={handleOpenModalNova}>Novo</button>
                </div>
            </div>
            <div className="imposto-filtro">
                <h2 style={{ margin: 10, color: '#fff' }}>Filtros: </h2>
                <TextField
                    id="datetime-inicio"
                    label="Inicial"
                    type="date"
                    defaultValue={primeiroDiaString}
                    onChange={(e) => handlePrimeiraData(e.target.value)}
                    variant="filled"
                    className="imp-custom"
                    InputLabelProps={{
                        shrink: true,
                        style: { color: 'white' }, // Cor do label
                    }}
                    InputProps={{
                        style: { color: 'white' }, // Cor do texto
                    }}
                />
                <span><h3>até</h3> </span>
                <TextField
                    id="datetime-final"
                    label="Final"
                    variant="filled"
                    type="date"
                    defaultValue={lastDayDate}
                    onChange={(e) => handleUltimaData(e.target.value)}
                    className="imp-custom"
                    InputLabelProps={{
                        shrink: true,
                        style: { color: 'white' }, // Cor do label
                    }}
                    InputProps={{
                        style: { color: 'white' }, // Cor do texto
                    }}
                />
                <select className='imposto-btn-filtrar'
                    onChange={(e) => setFiltro(e.target.value)}
                    defaultValue="1" // Define o valor padrão para o índice 1
                    required
                >
                    {
                        filtroStatus.map((number, index) => (
                            <option key={index} value={index} selected={number === 'Pendentes'}>{number}</option>
                        ))
                    }
                </select>
                <button className='imposto-btn-filtrar' onClick={handleFiltro} >Filtrar</button>

                <div className='imposto-div-total'>
                    <h2 style={{ color: '#fff' }}>Total do filtro: R$ {total}</h2>
                </div>
            </div>

            <div className="imposto-table">
                {/* <DataGrid */}
                <StyledDataGrid
                    rows={impostos}
                    columns={columns}
                    getRowClassName={(params) =>
                        params.row.status === 'Pendente' ? 'row-color-white' : 'row-color-green'
                    }
                    disableColumnResize={true}
                    // getRowClassName={getRowClassName}
                    // rowSpacing={3} // valor do espaçamento entre linhas em px
                    // columnSpacing={3} // valor do espaçamento entre colunas em px
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                id: false
                            },
                        },
                        pagination: {
                            paginationModel: { page: 0, pageSize: 10 },
                        },
                    }}
                    pageSizeOptions={[5, 10, 20, 50]}
                />
            </div>
        </div>
    );
}

export default Impostos;